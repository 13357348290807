import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import LayoutCard from 'components/layout/layoutCard';

function Custom404() {
  return <LayoutCard>404 - Page Not Found</LayoutCard>;
}

export const getStaticProps: GetStaticProps<any> = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale ?? 'en', ['common'])),
  },
});

export default Custom404;
